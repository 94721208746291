<template>
  <div class="homeBox">
    <router-view></router-view>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      isCollapse: false,
      activeIndex: this.deleteFristString(this.$route.path),
      // 然后再通过权限管理页面，去添加或修改这个树结构，这样的话，前端就会呈现出来了
      menuArr: [
      ],
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    // 去掉第一个字符
    deleteFristString(str) {
      return str.slice(1)
    },
  },
};
</script>

<style lang="less" scoped>
.homeBox {
  width: 100%;
  height: 100%;
  display: flex;
  .left {
    // 因为要折叠，所以看度不写死
    height: 100%;
    .leftNav {
      @media screen and (max-width: 1024px) {
        display: none;
      }
      min-width: 200px;
      max-width: 216px;
      height: 100%;
      overflow-y: scroll;
      .logo{
        border-bottom: 1px solid #282B2B;
        background: #1A1B1B;
        padding: 23px 0px;
        img{
          height: 34px;
          vertical-align: middle;
          margin: auto;
        }
      }
      .elMenu {
        width: 100%;
        height: 100%;
      }
      // 菜单选中高亮样式
      /deep/ .el-menu-item.is-active {
        background-color: rgba(255,221,153,0.1) !important;
        font-weight: bold;
        position: relative;
      }
      /deep/ .el-menu-item.is-active::before{
        content: '';
        width: 6px;
        display: inline-block;
        height: 100%;
        background: #FFDD99;
        border-radius: 0px 3px 3px 0px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
    /deep/ .el-menu-item{
      display: flex;
      align-items: center;
      img{
        margin-right: 10px;
      }
    }
  }
  .right {
    // 通过弹性盒左右自适应布局
    // flex: 1;
    width: calc(100vw - 206px);
    @media screen and (max-width: 1024px) {
      width: 100vw;
    }
    height: 100%;
    .rightTop {
      width: 100%;
      height: 80px;
      background-color: #1A1B1B;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      i {
        font-size: 36px;
        cursor: pointer;
      }
    }
    .rightBottom {
      width: 100%;
      height: calc(100% - 80px);
      overflow-y: scroll;
      background: #000000;
    }
  }
}

/*通过css隐藏文字，当菜单被折叠的时候，会多一个el-menu--collapse的类，所以
通过把这个类下的对应层级里面的标题文字和对应小箭头隐藏起来*/
/deep/ .el-menu--collapse .el-submenu .el-submenu__title span {
  display: none;
}
/deep/ .el-menu--collapse .el-submenu .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
.top_r{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #EEEEEE;
        margin-right: 10px;
      }
      .headimgs{
          width: 40px;
          height: 40px;
        position: relative;
          top: 0px;
          left: 0px;
        img{
          width: 40px;
          height: 40px;
          border-radius: 6px 6px 6px 6px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .dian{
          width: 11px;
          height: 11px;
          background: #09B66D;
          border-radius: 5px 5px 5px 5px;
          border: 3px solid #1F2935;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
}
</style>
